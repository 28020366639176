<template>
    <div class="login-page">
        <div class="logo"></div>
        <div class="loginBodyContent">
            <div id="content">
                <div id="login">
                    <div class="con">
                        <h1 class="title">
                            欢迎登录{{ appName }}
                        </h1>

                        <el-tabs
                            v-model="activeName"
                            type="border-card	"
                            @tab-click="handleClick"
                        >
                            <el-tab-pane label="账号密码" name="first">
                                <el-form ref="passWordForm" :model="passWordForm">
                                    <div
                                        class="top-boxs"
                                        :class="isShow ? 'inpYin' : 'inpXian'"
                                    >
                                        <el-form-item prop="userName">
                                            <el-input
                                                v-model.trim="passWordForm.userName"
                                                placeholder="请输入账号/邮箱"
                                                @keyup.native="testevent($event)"
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item prop="passWord">
                                            <el-input
                                                placeholder="请输入密码"
                                                v-model.trim="passWordForm.passWord"
                                                type="password"
                                                show-password
                                                @keyup.native="testevent($event)"
                                            ></el-input>
                                        </el-form-item>
                                    </div>
                                </el-form>
                            </el-tab-pane>
                            <!-- ++++++++++++++++++++++以上是密码登录+++++++++++++++++++ -->
                            <el-tab-pane
                                label="验证码"
                                name="second"
                                class="tab-color"
                                v-if="publicNetSwitch"
                            >
                                <el-form ref="ruleForm" :model="ruleForm">
                                    <div
                                        class="top-boxs"
                                        :class="isShow ? 'inpYin' : 'inpXian'"
                                    >
                                        <el-form-item prop="phone">
                                            <!-- <el-input placeholder="请输入内容"></el-input> -->
                                            <el-input
                                                v-model.trim="ruleForm.phone"
                                                placeholder="手机号"
                                                @keyup.native="testevent($event)"
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item class="send-code" prop="code">
                                            <el-input
                                                v-model.trim="ruleForm.code"
                                                class="sort-inp"
                                                placeholder="请输入验证码"
                                                maxlength="8"
                                                @keyup.native="testevent($event)"
                                            ></el-input>
                                            <el-button
                                                type="primary"
                                                class="primary-ssc"
                                                @click="getCode('ruleForm')"
                                            >{{ sendCode }}
                                            </el-button
                                            >
                                        </el-form-item>
                                    </div>
                                </el-form>
                            </el-tab-pane>

                            <el-tab-pane label="AK/SK登录" name="third">
                                <el-form ref="s3FormRef" :model="s3Form">
                                    <div
                                        class="top-boxs"
                                        :class="isShow ? 'inpYin' : 'inpXian'"
                                    >
                                        <el-form-item prop="AccessKey">
                                            <el-input
                                                v-model.trim="s3Form.ak"
                                                placeholder="输入AccessKey"
                                                @keyup.native="testevent($event)"
                                            ></el-input>
                                        </el-form-item>
                                        <el-form-item prop="SecretKey">
                                            <el-input
                                                placeholder="输入SecretKey"
                                                v-model.trim="s3Form.sk"
                                                type="password"
                                                show-password
                                                @keyup.native="testevent($event)"
                                            ></el-input>
                                        </el-form-item>
                                    </div>
                                </el-form>
                            </el-tab-pane>

                            <div v-if="slidingBlock">
                                <div
                                    class="slide-box"
                                    :class="isShow ? 'BlockXian' : 'BlockYin'"
                                >
                                    <div class="img-box">
                                        <div
                                            class="small-img"
                                            :style="{ top: sliderTop, left: sliderLeft }"
                                        >
                                            <img :src="smallimgSrc" alt class="img-src"/>
                                        </div>
                                        <div class="base-map">
                                            <img :src="bigimgsSrc" alt class="img-sr"/>
                                        </div>
                                    </div>
                                    <div class="slide-block">
                                        <div
                                            class="slide-verify-slider"
                                            :class="{
                                                  'container-active': containerActive,
                                                  'container-success': containerSuccess,
                                                  'container-fail': containerFail
                                                }"
                                            v-if="failshow"
                                        >
                                            <div
                                                class="slide-verify-slider-mask"
                                                :style="{ width: sliderMaskWidth }"
                                            >
                                                <div
                                                    @mousedown="sliderDown"
                                                    @touchstart="touchStartEvent"
                                                    @touchmove="touchMoveEvent"
                                                    @touchend="touchEndEvent"
                                                    class="slide-verify-slider-mask-item"
                                                    :style="{ left: sliderLeft }"
                                                >
                                                    <div class="slide-verify-slider-mask-item-icon">
                                                        >>>
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="slide-verify-slider-text">
                                              {{ sliderText }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="success-box" v-if="!failshow">
                    <span>
                      <i class="el-icon-success"></i>
                    </span>
                                    <span>验证通过</span>
                                </div>
                            </div>
                            <el-button
                                type="primary"
                                @click="LoginpassWord"
                                :class="slidingBlock ? 'send-login' : 'send-login-top'"
                            >{{ btnLoginName }}
                            </el-button
                            >
                            <div class="big-other">
                                <p
                                    class="other"
                                    :class="'ssc-other'"
                                >
                                    <el-link
                                        type="primary"
                                        :underline="false"
                                        @click="goForget"
                                        class="otherOperation top-conent-ssc-col"
                                    >忘记密码
                                    </el-link>
                                </p>
                            </div>

                            <p class="tostmsg">{{ tostMsg }}</p>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cookie from "js-cookie";
import {mapGetters} from "vuex";
import storage from "@/utils/storage";

let wait = 60
export default {
    name: "Login",
    computed: {
        ...mapGetters(['publicNetSwitch', 'license', 'appName'])
    },
    data() {
        return {
            activeName: 'first',
            passWordForm: {
                userName: '',
                passWord: ''
            },
            btnLoginName: '登录',
            sliderText: '请拖动滑块，完成验证',
            containerActive: false,
            containerSuccess: false,
            containerFail: false,
            sliderLeft: 0,
            sliderMaskWidth: 0,
            isShow: false,
            ruleForm: {
                phone: '',
                code: ''
            },
            sendCode: '发送验证码',
            slidingBlock: true,
            sliderTop: 0,
            smallimgSrc: '',
            bigimgsSrc: '',
            failshow: true,
            trail: [],
            tostMsg: '',
            pk: '',
            s3Form: {
                ak: '',
                sk: '',
            }
        }
    },

    created() {
        document.cookie = ''
        const {message} = this.$route.params
        if (message) {
            this.$message.warning(message)
        }
    },
    mounted() {
        this.getImage();
        this.bindEvents();
    },
    methods: {
        bindEvents() {
            document.addEventListener('mousemove', e => {
                if (!this.isMouseDown) {
                    return false;
                }
                const moveX = e.clientX - this.originX;
                const moveY = e.clientY - this.originY;

                if (moveX < 0 || moveX + 38 >= this.w) {
                    return false;
                }
                this.sliderLeft = moveX + 'px';

                this.containerActive = true; // add active
                this.sliderMaskWidth = moveX + 'px';
                if (moveX > 320 - 57) {
                    this.sliderLeft = 320 - 57 + 'px';
                    this.sliderMaskWidth = 320 - 57 + 'px';
                }
                this.slidingBlockWidth = '';
                this.slidingBlockWidth = this.sliderLeft.split('px').join('');
                this.trail.push(moveY);
            });
            document.addEventListener('mouseup', e => {
                if (!this.isMouseDown) {
                    return false;
                }
                //抬起
                this.isMouseDown = false;
                this.isShow = false;
                this.upmouseEvent();
                if (e.clientX === this.originX) {
                    return false;
                }
                this.containerActive = false;
            });
        },
        upmouseEvent() {
            let data = {
                slidingBlockWidth: Number(this.slidingBlockWidth),
                serialNumber: this.serialNumber
            };
            this.$postJson('/login/slidingBlock/verifyImage', data).then(res => {
                console.log(res)
                if (res.code === '200') {
                    this.pk = res.data;
                    this.tostMsg = '';
                    this.failshow = false;
                }
            })
            .catch(err => {
                this.failshow = true;
                this.tostMsg = '滑块验证失败';
                this.reset();
                this.failshow = true;
                this.getImage();
            })
        },
        testevent(eve) {
            if (eve.keyCode == 13) {
                this.LoginpassWord();
            }
        },
        handleClick() {
            this.getImage();
            this.reset();
            this.failshow = true;
            this.tostMsg = '';
        },
        getImage() {
            this.$get('/login/slidingBlock/getImage')
                .then(res => {
                    if (res.code === '200') {
                        this.smallimgSrc = res.data.smallImage; //bigImage
                        this.bigimgsSrc = res.data.bigImage;
                        this.sliderTop = Number(res.data.yHeight) + 'px';
                        this.serialNumber = res.data.serialNumber;
                    }
                });
        },
        reset() {
            this.btnLoginName = '登录';
            this.sliderText = '请拖动滑块，完成验证';
            this.containerActive = false;
            this.containerSuccess = false;
            this.containerFail = false;
            this.sliderLeft = 0;
            this.sliderMaskWidth = 0;
            this.pk = ''
            this.s3Form.ak = ''
            this.s3Form.sk = ''
        },
        getCode() {
            var ema = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (this.ruleForm.phone == '') {
                this.tostMsg = '手机号不能为空';
                return;
            }
            if (ema.test(this.ruleForm.phone) == false) {
                this.tostMsg = '手机号格式不正确';
                return;
            }
            this.sendCode = '发送中...'
            this.$postJson('/login/sms?phone=' + this.ruleForm.phone)
                .then(res => {
                    if (res.code === '200') {
                        let timeOut = setInterval(() => {
                            if (wait === 0) {
                                this.sendCode = '发送验证码';
                                wait = 60;
                                clearInterval(timeOut);
                            } else {
                                this.sendCode = '已发送（' + wait + ')';
                                wait--;
                                // }
                            }
                        }, 1000);
                    }
                })
                .catch(err => {
                    this.sendCode = '发送验证码';
                    this.tostMsg = err.message;
                })
        },
        sliderDown(event) {
            this.isShow = true; //图片是否出现
            this.originX = event.clientX;
            this.originY = event.clientY;
            this.isMouseDown = true;
        },
        touchStartEvent(e) {
            this.originX = e.changedTouches[0].pageX;
            this.originY = e.changedTouches[0].pageY;
            this.isMouseDown = true;
        },
        touchMoveEvent(e) {
            if (!this.isMouseDown) {
                return false;
            }
            const moveX = e.changedTouches[0].pageX - this.originX;
            const moveY = e.changedTouches[0].pageY - this.originY;

            if (moveX < 0 || moveX + 38 >= this.w) {
                return false;
            }
            this.sliderLeft = moveX + 'px';
            let blockLeft = ((this.w - 40 - 20) / (this.w - 40)) * moveX;
            this.containerActive = true;
            this.sliderMaskWidth = moveX + 'px';
            this.trail.push(moveY);
        },
        touchEndEvent(e) {
            if (!this.isMouseDown) {
                return false;
            }
            this.isMouseDown = false;
            if (e.changedTouches[0].pageX === this.originX) {
                return false;
            }
            this.containerActive = false;

            // const { spliced, TuringTest } = this.verify();

            if (spliced) {
                if (this.accuracy === -1) {
                    this.containerSuccess = true;
                    this.$emit('success');
                    return;
                }
                if (TuringTest) {
                    // succ
                    this.containerSuccess = true;
                    this.$emit('success');
                } else {
                    this.containerFail = true;
                    this.$emit('again');
                }
            } else {
                this.containerFail = true;
                this.$emit('fail');
                setTimeout(() => {
                    this.reset();
                    this.failshow = true;
                }, 1000);
            }
        },
        LoginpassWord() {
            if (this.btnLoginName == '登录') {
                this.btnLoginName = '登录中...';
                if (this.activeName == 'first') {
                    if (
                        this.passWordForm.userName == '' ||
                        this.passWordForm.passWord == ''
                    ) {
                        this.btnLoginName = '登录';
                        this.tostMsg = '账号密码不能为空';
                        return;
                    }
                    if (this.pk == '') {
                        this.btnLoginName = '登录';
                        this.tostMsg = '请拖动滑块，完成验证';
                    } else {
                        this.tostMsg = '';
                        this.passLogin(); //暂时
                    }
                } else if(this.activeName === 'third') {
                    this.s3Login();
                } else {
                    this.codeLogin();
                }
            }
        },
        // S3认证登录
        s3Login (){
            if (this.s3Form.ak === '' || this.s3Form.sk === '') {
                this.tostMsg = 'AccessKey和SecretKey不能为空';
                this.btnLoginName = '登录';
                return;
            }
            if (this.pk == '') {
                this.btnLoginName = '登录';
                this.tostMsg = '请拖动滑块，完成验证';
            } else {
                this.$postJson('/aksk/login', this.s3Form)
                    .then(res => {
                        if (res.code === '200') {
                            this.$store.commit('user/SET_USER_INFO', res.data)
                            this.handleRouterAndRegion(res.data)
                        }
                    })
					.catch(err => {
						this.btnLoginName = '登录';
						this.getImage();
						this.reset();
						this.failshow = true;
						this.tostMsg = err.message
					});
            }
        },
        //验证及登录前的校验
        codeLogin() {
            if (this.ruleForm.phone == '' || this.ruleForm.code == '') {
                this.tostMsg = '手机号和验证码不能为空';
                this.btnLoginName = '登录';
                return;
            }
            // let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            let ema = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (
                // reg.test(this.ruleForm.phone) == false &&
                ema.test(this.ruleForm.phone) == false
            ) {
                this.btnLoginName = '登录';
                this.tostMsg = '手机号格式不正确';
                return;
            }
            if (this.pk == '') {
                this.btnLoginName = '登录';
                this.tostMsg = '请拖动滑块，完成验证';
            } else {
                this.verifyCode();
            }
        },
        //验证码登录
        verifyCode() {
            var ema = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (ema.test(this.ruleForm.phone)) {
                // if (this.newSetObj.slidingBlock == true) {
                if (!!1) {
                    let data = {
                        phone: this.ruleForm.phone,
                        code: this.ruleForm.code
                    };
                    this.$postJson(`/sms/login?phone=${this.ruleForm.phone}&code=${this.ruleForm.code}`)
                        .then(res => {
                            if (res.code === '200') {
                                this.$store.commit('user/SET_USER_INFO', res.data)
                                this.handleRouterAndRegion(res.data)
                            }
                        })
                        .catch(err => {
                            this.btnLoginName = '登录';
                            this.getImage();
                            this.reset();
                            this.failshow = true;
                            this.tostMsg = err.message
                        });
                } else {
                    // let data = {
                    //     phoneNumber: this.ruleForm.phone,
                    //     messageCode: this.ruleForm.code,
                    //     bizId: this.bizId,
                    //     type: this.type
                    // };
                    // verifyCodeLogin(data).then(res => {
                    //     if (res.code == 200) {
                    //         setCookie('EHLTGT', res.data);
                    //         let obj = {
                    //             tgt: res.data,
                    //             serviceUrl: encodeURIComponent(this.str1)
                    //         };
                    //         this.intoPage(obj);
                    //     } else if (res.code == 703) {
                    //         this.getImage();
                    //         this.reset();
                    //         this.failshow = true;
                    //         this.tostMsg = '';
                    //         this.userid = res.data;
                    //         this.getMsg(this.userid);
                    //     } else {
                    //         this.getImage();
                    //         this.reset();
                    //         this.failshow = true;
                    //         this.tostMsg = res.msg;
                    //     }
                    // });
                }
            }
        },
        /**
         * 登录成功之后处理路由和资源池
         * * **/
        handleRouterAndRegion ({regionList = [], role, token}) {
            storage.set('PRIVATE_BLUESTORE_TOKEN', token)
            let regionId = storage.get('REGION_ID')
            if (!regionList.find(item => regionId === item.regionId) && regionList.length > 0) {
                storage.set('REGION_ID', regionList[0].regionId)
            }
            if (role === 'ROLE_LOGGER') {
                this.$router.push('/syslog')
            } else  if (localStorage.getItem('regionType') === 'GDAS') {
                this.$router.push('/bucket')
            } else {
                this.$router.push('/')
            }
        },


        passLogin() {
            // if (this.newSetObj.slidingBlock == true) {
            if (!!1) {

				let data = {
					username: this.passWordForm.userName,
					password: this.$encruption(this.passWordForm.passWord)
				}
				this.$postJson(`/password/login?pk=` + this.pk, data, {})
                // this.$post(`/login?username=${this.passWordForm.userName}&password=${this.passWordForm.passWord}&pk=${this.pk}`)
                    .then(res => {
                    if (res.code === '200') {
                        /**
                         * bid: "1"
                         * createdAt: "Feb 28, 2022 3:35:41 PM"
                         * id: 1
                         * isDeleted: false
                         * orgId: "e6ca6368557243c0944e4c7181f0d81a"
                         * password: ""
                         * phone: ""
                         * regionId: "224"
                         * role: "ROLE_ADMIN"
                         * status: "NORMAL"
                         * subType: 0
                         * updatedAt: "Mar 22, 2022 11:21:08 AM"
                         * userSource: "UNICOM_CM"
                         * username: "admin"
                         * **/
                        this.$store.commit('user/SET_USER_INFO', res.data)

                        this.handleRouterAndRegion(res.data)
                    }
                })
                .catch(err => {
                    this.btnLoginName = '登录';
                    this.getImage();
                    this.reset();
                    this.failshow = true;
                    this.tostMsg = err.message
                });
            } else {
                // let data = {
                //     identification: this.passWordForm.userName,
                //     password: this.$encruption(this.passWordForm.passWord),
                //     serviceUrl: encodeURIComponent(this.str1)
                // };
                // passWordLogin(data).then(res => {
                //     if (res.code == 200) {
                //         setCookie('EHLTGT', res.data);
                //         let obj = {
                //             tgt: res.data,
                //             serviceUrl: encodeURIComponent(this.str1)
                //         };
                //
                //         this.intoPage(obj);
                //     } else {
                //         this.getImage();
                //         this.reset();
                //         this.failshow = true;
                //         if (res.code == 999) {
                //             this.tostMsg = '登陆失败，请重试';
                //         } else if (res.code == 703) {
                //             this.getImage();
                //             this.reset();
                //             this.failshow = true;
                //             this.tostMsg = '';
                //             this.userid = res.data;
                //             this.getMsg(this.userid);
                //         } else if (res.code == 707) {
                //             this.getImage();
                //             this.reset();
                //             this.failshow = true;
                //             this.tostMsg = res.msg;
                //             this.$store.commit('userMsg', {
                //                 password: this.$encruption(this.passWordForm.passWord),
                //                 username: res.data.username,
                //                 phoneNumber: res.data.phone,
                //                 hasPhone: Boolean(res.data.phone),
                //                 hasUser: Boolean(res.data.userId)
                //             });
                //         } else {
                //             this.getImage();
                //             this.reset();
                //             this.failshow = true;
                //             this.tostMsg = res.msg;
                //         }
                //     }
                // });
            }
        },
        goForget() {
            if (this.publicNetSwitch) {
                this.$router.push('/forget')
            } else {
                this.$confirm("请联系客户经理", "提示", {
                    confirmButtonText: "确定",
                    showCancelButton: false,
                    type: "warning",
                })
            }
        }
    }
}
</script>

<style lang="scss">
.login-page {
    width: 100%;
    height: 100%;
    background: #F8FCFE url("../assets/images/login_bg.png") no-repeat center center;
    background-size: 1920px 1080px;
    position: relative;
}

.login-page .logo {
    position: absolute;
    top: 47px;
    left: 60px;
    width: 138px;
    height: 30px;
    //background: url("../assets/images/logo_bg.png") no-repeat center;
    //background-size: 138px 30px;
}

.slide-box {
    width: 320px;
    height: 200px;
    position: absolute;
    top: 1px;

    .img-box {
        position: absolute;
        left: 0;
        top: 0;
    }

    .base-map {
        position: absolute;
        left: 0;
        top: 0;
    }

    .small-img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
    }

    .small-img {
        width: 55px !important;
        height: 45px !important;
    }

    .img-sr {
        width: 320px !important;
        height: 155px !important;
    }
}


.inpYin {
    z-index: 1;
}

.inpXian {
    z-index: 10;
}

.BlockXian {
    z-index: 10;
}

.BlockYin {
    z-index: 1;
}


.login-page {
    .loginBodyContent {
        #content {
            #login {
                .con {
                    .el-tabs__nav {
                        .is-active {
                            border: 1px solid #ff8700 !important;
                            background: #ff8700;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .success-box {
        border: 1px #379f04 solid;
        background: rgba(55, 159, 4, 0.5);
        color: #fff;
    }

    .slide-verify-slider-mask {
        border: 0 solid #ff8700;
        background: #fdcc93;
    }

    .slide-verify-slider-mask-item:hover {
        background: #ff8700;
    }

    .slide-verify-slider-mask-item-icon {
        color: #fff;
        background: #ff8700;
    }

    .container-active .slide-verify-slider-mask-item {
        border: 1px solid #ff8700;
    }

    .container-success .slide-verify-slider-mask-item {
        border: 1px solid #52ccba;
        background-color: #52ccba !important;
    }

    .container-success .slide-verify-slider-mask {
        height: 38px;
        border: 1px solid #52ccba;
        background-color: #d2f4ef;
    }

    .container-success .slide-verify-slider-mask-item-icon {
        background-position: 0 0 !important;
    }

    .container-fail .slide-verify-slider-mask-item {
        border: 1px solid #f57a7a;
        background-color: #f57a7a !important;
    }

    .container-fail .slide-verify-slider-mask {
        border: 1px solid #f57a7a;
        background-color: #fce1e1;
    }
}

.send-login-top,
.primary-ssc,
.send-login {
    background: #ff8700 !important;
    border: 1px solid #ff8700 !important;
}

.send-login-top:hover,
.send-login:hover,
.send-login-top:focus,
.send-login:focus,
.primary-ssc:hover,
.primary-ssc:focus {
    background-color: #fdb057 !important;
    border-color: #fdb057 !important;
}

.top-conent-ssc-col .el-link--inner {
    color: #ff8700;
}

.el-tabs__item:hover {
    color: #ff8700;
}


.login-page {
    width: 100%;
    height: 100%;
    min-width: 1200px;
    margin: 0;
    padding: 0;

    .top-boxs {
        padding-top: 30px;
        padding-bottom: 3px;
        background: #fff;
        position: absolute;
    }

    .version {
        color: #fff;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        font-size: 14px;
        height: 70px;
        width: 100%;

        .logo1 {
            cursor: pointer;
            margin-left: 360px;
            padding-top: 20px;
        }
    }

    .login {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        background-size: 100% 100%;
        background-position: 50%;
        // background: #fcfcfc;
        .loginBodyBigLogo {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .loginBodyBigLogo-ssc {
            position: absolute;
            top: 25%;
            left: 7vw;
            width: 40.5vw;
            height: 52.5vh;
        }
    }

    .register {
        height: 888px;
        width: 100%;
        position: relative;
        bottom: 0;

        .img {
            height: 888px;
            overflow: hidden;
        }
    }

    .loginBodyBox {
        width: 100%;
        max-width: 1300px;
        height: 100%;
        margin: auto;
    }

    .loginBodyContent {
        position: absolute;
        margin: auto 0;
        top: 50%;
        right: 10%;
        transform: translate(0, -50%);
        width: auto;
        height: auto;
        background: #fff;
        box-shadow: 0px 2px 14px 0px rgba(1, 40, 71, 0.2);

        #content {
            padding: 30px 55px 30px 39px;
            overflow: hidden;

            #login {
                width: 336px;
                float: left;

                .con {
                    padding-left: 16px;

                    .el-tabs__nav {
                        border: none;
                        border-radius: 2px;

                        .el-tabs__item {
                            font-size: 14px;
                            text-align: center;
                            padding: 0;
                            width: 80px;
                            border: 1px #ccc solid;
                        }

                        // .is-active {
                        //   border: 1px solid #ff8700 !important;
                        //   background: #ff8700;
                        //   color: #fff;
                        // }
                    }

                    .send-code {
                        .sort-inp {
                            width: 191px;
                        }

                        .el-button {
                            width: 104px;
                            padding: 12px 9px;
                            margin-left: 24px;
                        }
                    }

                    .send-login {
                        width: 320px;
                        padding: 12px 9px;
                        margin-top: 220px !important;
                    }

                    .send-login-top {
                        width: 320px;
                        padding: 12px 9px;
                        margin-top: 160px;
                    }

                    .slide-verify-slider {
                        width: 318px;
                    }

                    .big-other {
                        width: 100%;
                        margin-top: 19px;
                        line-height: 16px;
                        height: 16px;
                        margin-bottom: 10px;

                        .other {
                            text-align: right;
                            width: 100%;
                            float: right;

                            .el-link {
                                font-size: 12px;
                            }

                            time {
                                color: #ccc;
                            }
                        }

                        .ssc-other {
                            text-align: right;
                        }
                    }

                    .tostmsg {
                        font-size: 14px;
                        color: #f00;
                        word-break: break-word;
                    }

                    .big-wei {
                        .left-border {
                            width: 121px;
                            margin-top: 20px;
                            border-bottom: 0.5px solid #efefef;
                            float: left;
                        }

                        .left-border:nth-child(1) {
                            margin-right: 3px;
                        }

                        .left-border:nth-child(3) {
                            margin-left: 3px;
                        }

                        .wxlogin {
                            text-align: center;
                            float: left;

                            .el-button {
                                padding: 0;
                                border: none;
                                padding-bottom: 0px;
                                float: left;
                                padding-top: 13px;
                                // margin-top: 5px;
                            }

                            .iconfont {
                                font-size: 18px;
                            }

                            .wei {
                                margin-left: 6px;
                                font-size: 12px;
                                line-height: 42px;
                                display: inline-block;
                                color: #999999;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    .canvas-show {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .title {
                    font-size: 24px;
                    line-height: 33px;
                    margin: 20px 0 23px 0px;
                    font-weight: normal;
                }

                .el-tabs__nav-wrap::after {
                    display: none !important;
                }

                .el-form-item {
                    width: 320px;

                    .el-input {
                        width: 320px;

                        .el-input__inner {
                            border-radius: 2px;
                        }
                    }
                }
            }
        }
    }

    .success-box {
        width: 317px;
        height: 42px;
        text-align: center;
        position: absolute;
        top: 160px;
        left: 0;
        line-height: 42px;

        span {
            float: left;
        }

        i {
            padding-left: 106px;
            padding-right: 10px;
            line-height: 40px;
            font-size: 20px;
        }
    }

    .second-box {
        position: relative;
    }

    .slide-verify {
        position: absolute !important;
        width: 320px;
        height: 200px;
        left: 0;
        top: 0px;
    }

    .slide-verify-block {
        position: absolute;
        left: 0;
        top: 0;
    }

    .slide-verify-refresh-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 34px;
        height: 34px;
    }

    .slide-verify-slider {
        position: absolute;
        bottom: -2px;
        left: 0;
        text-align: center;
        width: 318px;
        height: 40px;
        line-height: 40px;
        margin-top: 15px;
        background: #f7f9fa;
        color: #45494c;
        border: 1px solid #e4e7eb;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .slide-verify-slider-mask {
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
    }

    .slide-verify-slider-mask-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 55px;
        height: 40px;
        background: #fff;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transition: background 0.2s linear;
    }

    .slide-verify-slider-mask-item:hover .slide-verify-slider-mask-item-icon {
        background-position: 0 -13px;
        color: #fff;
    }

    .container-active .slide-verify-slider-mask-item {
        height: 38px;
        top: -1px;
    }

    .container-active .slide-verify-slider-mask {
        height: 38px;
        border-width: 1px;
    }

    .container-success .slide-verify-slider-mask-item {
        height: 38px;
        top: -1px;
    }

    .container-success .slide-verify-slider-mask {
        height: 38px;
    }

    .container-success .slide-verify-slider-mask-item-icon {
        background-position: 0 0 !important;
    }

    .container-fail .slide-verify-slider-mask-item {
        height: 38px;
        top: -1px;
    }

    .container-fail .slide-verify-slider-mask {
        height: 38px;
    }

    .container-fail .slide-verify-slider-mask-item-icon {
        top: 14px;
        background-position: 0 -82px !important;
    }

    .slide-verify-slider-text {
        font-size: 14px;
    }
}
</style>
